<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-view />

    <core-footer />

    <!-- <core-cta /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'App',
    // metaInfo: {
    //   title: 'Pflasterer, Fliesenleger für Naturstein in Gmunden',
    //   meta: [
    //     { 
    //       name: 'description', content: 'Vali-Naturstein ist Ihr Ansprechpartner beim Verlegen von Pflaster-, Platten- & Fliesen. Pflasterer sowie Fliesenlegermeister in der Region Gmunden und Regau, von Vöcklabruck bis Attersee.'
    //     },
    //     { 
    //       name: 'keywords', content: 'Pflasterer, Fliesen, Platten, Pflasterungen, Feinsteinzeug, Naturstein, Stiegen, Badezimmer, Natursteinverlegung, Terrassenbau, Pooleinfassung, Poolumrandung, Attersee, Traunsee, Gmunden, Vöcklabruck, Oberösterreich'
    //     },
    //   ]
    // },

    components: {
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreView: () => import('@/components/core/View'),
      CoreFooter: () => import('@/components/core/Footer'),
      // CoreCta: () => import('@/components/core/Cta'),
    },
  }
</script>

