<template>
  <div id="home">
    <referenzen>
      <banner />
    </referenzen>

    <v-divider></v-divider>

    <v-container>
      <v-row>
        <v-col class="text-center">
          <h1 class="body-1">
            Natursteine sind <b>zeitlos</b> sowie <b>pflegeleicht und langlebig</b>!
          </h1>
          <h1 class="body-1 py-2">
            Diese Eigenschaften gepaart mit außergewöhnlicher Optik machen den
            Naturstein für viele Einsatzgebiete zum idealen Baumaterial.
          </h1>
          <h1 class="body-1 py-2">
            <b>Durch meine langjährige Erfahrung im Verlegen von Fliesen, Platten
            und Pflastersteinen kann ich bei jedem Projekt perfekt unterstützen.</b>
          </h1>
          <h1 class="body-1">
            Egal ob Fliesenarbeiten in Bad, Küche oder Keller - oder Platten für Poolumrandungen, Terrassen oder Stiegen.
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>

    <schauraum />

    <v-divider></v-divider>

    <kontakt />

    <!-- <subscribe />

    <social /> -->
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {
    Referenzen: () => import("@/components/home/Referenzen"),
    Banner: () => import("@/components/home/Banner"),
    Schauraum: () => import("@/components/home/Schauraum"),
    Kontakt: () => import("@/components/home/Kontakt"),

    //   Social: () => import('@/components/home/Social'),
    //   Subscribe: () => import('@/components/home/Subscribe'),
  },
};
</script>
