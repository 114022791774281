import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    referenzen: require('@/assets/referenzen/referenzen.json'),
    drawer: false,
    items: [
      // {
      //   text: 'Home',
      //   href: '#!',
      // },
      {
        text: 'Referenzen',
        href: '#referenzen',
      },
      {
        text: 'Schauraum',
        href: '#schauraum',
      },
      {
        text: 'Kontakt',
        href: '#kontakt',
      },
    ],
  },
  getters: {
    // categories: state => {
    //   const categories = []

    //   for (const article of state.referenzen) {
    //     if (
    //       !article.was ||
    //       categories.find(was => was.text === article.was)
    //     ) continue

    //     const text = article.was

    //     categories.push({
    //       text,
    //       href: '#!',
    //     })
    //   }

    //   return categories.sort().slice(0, 4)
    // },
    // links: (state, getters) => {
    //   return state.items.concat(getters.categories)
    // },
    links: (state) => {
      return state.items
    },
  },
  mutations: {
    setDrawer: (state, payload) => (state.drawer = payload),
    toggleDrawer: state => (state.drawer = !state.drawer),
  },
  actions: {

  },
})
